import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "../../common/pageheader";
import PaginationComponent from "../../common/pagination";
import axios from "../../../services/axios";
import { Home } from "react-feather";
import SimpleLoader from "react-simple-dots-loader";
import Checkbox from "./checkbox";
import { Link } from "react-router-dom";
import MpesaActionPanel from "../../common/mpesaActionPanel";
import SweetAlert from "sweetalert2";

const AllocateAccounts = () => {
  let history = useHistory();
  const [mpesa_list, setMpesaList] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [selectedAccount, setSelectedAccount] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [account_list, setAccountList] = useState([]);

  const currUser = JSON.parse(localStorage.getItem("c360-user"));

  useEffect(() => {
    getData();
    getAccountList();
  }, []);

  //Fetch data from API endpoint
  const getData = async () => {
    setLoading(true);
    let currUser = JSON.parse(localStorage.getItem("c360-user"));
    let today = new Date();
    let searchdate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    const params = {
      tenantid: currUser.user.Partner.identifier,
      searchKey: searchKey,
      action: "display",
      page: currentPage,
      pagesize: perPage,
      startdate: "2020-01-01",
      enddate: searchdate,
    };

    let response = await axios.get("/onlinetrans/0", { params });
    console.log(response);
    setMpesaList(response.data.transactions);
    setTotalCount(response.data.total);
    setLoading(false);
  };

  const getAccountList = async () => {
    setLoading(true);
    let currUser = JSON.parse(localStorage.getItem("c360-user"));
    let today = new Date();
    let searchdate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    const params = {
      tenantid: currUser.user.Partner.identifier,
      searchKey: "",
      action: "display",
      page: 1,
      pagesize: 25,
      startdate: "2020-01-01",
      enddate: searchdate,
    };

    let response = await axios.get("/account", { params });
    console.log(response);
    setAccountList(response.data.accounts);
    setLoading(false);
  };

  // Function to load a specific page of data from API
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // console.log(e);
    setMpesaList([]);
    setCurrentPage(e);
    getData();
  };

  // Function to perform search based on provided searchKey
  const handleSearch = () => {
    console.log(searchKey);
    console.log("searchKey");
    setMpesaList([]);
    getData();
  };

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(mpesa_list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      account: selectedAccount,
      selectedRecords: isCheck.join(),
    };

    console.log(data);
    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }

    axios
      .post(
        "/allocateaccounts?tenantid=" + currUser.user.Partner.identifier,
        data
      )
      .then((res) => {
        console.log(res);
        SweetAlert.fire({
          title: "Awesome!",
          text: "New income saved successfully!",
          icon: "success",
        });

        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
          SweetAlert.fire({
            title: "Error!",
            text: error.response.data.status_msg,
            icon: "error",
          });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          SweetAlert.fire({
            title: "Error!",
            text: "The request was made but no response was received",
            icon: "error",
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          SweetAlert.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
          });
        }
      });

    // console.log('testing');
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
    return false;
  };

  const handleClick = (e) => {
    let { id, checked } = e.target;
    id = Number(e.target.id);

    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col">
              <div className="page-header-left">
                <h3>M-pesa</h3>
                <ol className="breadcrumb pull-right">
                  <li className="breadcrumb-item">
                    <Link to={`${process.env.PUBLIC_URL}/dashboard/default`}>
                      <Home />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Finance</li>
                  <li className="breadcrumb-item active">M-pesa</li>
                </ol>
              </div>
            </div>

            <MpesaActionPanel
              onSearch={handleSearch}
              serchText={setSearchKey}
              submitAction={handleSubmit}
              setAccount={setSelectedAccount}
              selectedRecords={isCheck}
              accountList={account_list}
            />
            {/* <!-- Bookmark Ends--> */}
          </div>
        </div>
      </div>

      {mpesa_list ? (
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-xl-12 xl-100">
                  <div className="user-status table-responsive">
                    <table className="table table-bordernone ">
                      <thead>
                        <tr>
                          <th>
                            <Checkbox
                              type="checkbox"
                              name="selectAll"
                              id="selectAll"
                              handleClick={handleSelectAll}
                              isChecked={isCheckAll}
                            />
                          </th>
                          <th scope="col">{"Sender"}</th>
                          <th scope="col">{"Mobile"}</th>
                          <th scope="col">{"Amount"}</th>
                          <th scope="col">{"Trx id"}</th>
                          <th scope="col">{"Trx Time"}</th>
                        </tr>
                      </thead>

                      {loading && (
                        <tbody>
                          <tr>
                            <td colSpan="8">
                              <div className="row mb-3"></div>

                              <div className="row mb-3">
                                <span className="">
                                  &nbsp;&nbsp;&nbsp; Loading Data{" "}
                                  <SimpleLoader color="blue" />
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                      <tbody>
                        {mpesa_list.map((data) => (
                          <tr key={data.id}>
                            <td>
                              {" "}
                              <Checkbox
                                key={data.id}
                                type="checkbox"
                                name={data.sender}
                                id={data.id}
                                handleClick={handleClick}
                                isChecked={isCheck.includes(data.id)}
                              />
                            </td>
                            <td>{data.sender}</td>
                            <td>{data.msisdn}</td>
                            <td>{data.amount}</td>
                            <td>{data.trxnid}</td>
                            <td>{data.trxTime}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <PaginationComponent
                  activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={totalCount}
                  onChange={handlePageClick}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default AllocateAccounts;
