import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Star, Search, PlusCircle } from "react-feather";
const MpesaActionPanel = (props) => {


 
  return (
    <Fragment>
        <div className="col-sm-6 float-right">
        <div className="job-filter">
          <div className="faq-form">
       <form onSubmit={props.submitAction}>
       <div className="form-row">
                      {/* <label htmlFor="exampleFormControlInput1">
                        Account:<span className="font-danger">*</span>
                      </label> */}
                        <div className="col-sm-2"></div>

                      <div className="col-sm-5">
                   
                      <div className="form-group">
                        <select
                          name="account" className="form-control"
                          onChange={(event) => props.setAccount(event.target.value)}
                         
                        > <option value="0">Select Account</option>
                          {props.accountList.map((data) => (
                           
                            <option value={data.id}>{data.name}</option>
                          ))}
                        </select>
                      </div>
                      </div>
                  <input type="hidden" name="selectedRecords" value={props.selectedRecords} readOnly></input>



                  <div className="col-sm-5">
          <input
            className="btn btn-primary "
            type="submit" value="Submit"
          />
          </div>
          </div>
      </form>
          </div>
        </div>
      </div>
      
    </Fragment>
  );
};

export default MpesaActionPanel;
