import React, { Fragment, useState, useEffect } from "react";
import PageHeader from "../../common/pageheader";
import PaginationComponent from "../../common/pagination";
import axios from "../../../services/axios";
import { Edit } from "react-feather";
import SimpleLoader from "react-simple-dots-loader";
import Checkbox from "./checkbox";

const Mpesa = () => {
  const [mpesa_list, setMpesaList] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    getData();
  }, []);

  //Fetch data from API endpoint
  const getData = async () => {
    setLoading(true);
    let currUser = JSON.parse(localStorage.getItem("c360-user"));
    let today = new Date();
    let searchdate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    const params = {
      tenantid: currUser.user.Partner.identifier,
      searchKey: searchKey,
      action: "display",
      page: currentPage,
      pagesize: perPage,
      startdate: "2020-01-01",
      enddate: searchdate,
    };

    let response = await axios.get("/onlinetrans", { params });
    console.log(response);
    setMpesaList(response.data.transactions);
    setTotalCount(response.data.total);
    setLoading(false);
  };

  // Function to load a specific page of data from API
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // console.log(e);
    setMpesaList([]);
    setCurrentPage(e);
    getData();
  };

  // Function to perform search based on provided searchKey
  const handleSearch = () => {
    console.log(searchKey);
    console.log("searchKey");
    setMpesaList([]);
    getData();
  };

  return (
    <Fragment>
      <PageHeader
        title="Mpesa"
        parent="Finance"
        onSearch={handleSearch}
        serchText={setSearchKey}
        addButtonLink="/finance/mpesa/allocate"
        addButtonName="Allocate Accounts"
      />

      {mpesa_list ? (
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-xl-12 xl-100">
                  <div className="user-status table-responsive">
                    <table className="table table-bordernone ">
                      <thead>
                        <tr>
                          <th scope="col">{"Sender"}</th>
                          <th scope="col">{"Mobile"}</th>
                          <th scope="col">{"Amount"}</th>
                          <th scope="col">{"Trx id"}</th>
                          <th scope="col">{"Trx Time"}</th>
                        </tr>
                      </thead>

                      {loading && (
                        <tbody>
                          <tr>
                            <td colSpan="8">
                              <div className="row mb-3"></div>

                              <div className="row mb-3">
                                <span className="">
                                  &nbsp;&nbsp;&nbsp; Loading Data{" "}
                                  <SimpleLoader color="blue" />
                                </span>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      )}
                      <tbody>
                        {mpesa_list.map((data) => (
                          <tr key={data.id}>
                            <td>{data.sender}</td>
                            <td>{data.msisdn}</td>
                            <td>{data.amount}</td>
                            <td>{data.trxnid}</td>
                            <td>{data.trxTime}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <PaginationComponent
                  activePage={currentPage}
                  itemsCountPerPage={perPage}
                  totalItemsCount={totalCount}
                  onChange={handlePageClick}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Mpesa;
