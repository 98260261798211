import {
  Home,
  Box,
  DollarSign,
  UserPlus,
  Users,
  Chrome,
  Settings,
  Airplay,
  FolderPlus,
  File,
  Command,
  Cloud,
  Book,
  FileText,
  Server,
  Image,
  Sliders,
  Map,
  GitPullRequest,
  Calendar,
  Edit,
  Mail,
  MessageSquare,
  UserCheck,
  Layers,
  HelpCircle,
  Database,
  Headphones,
  Mic,
  ShoppingBag,
  Search,
  AlertOctagon,
  Lock,
  Briefcase,
  BarChart,
  Target,
  List,
  Package,LogIn,LogOut
} from "react-feather";

export const ETRACKER_MENUITEMS = [
  {
    path: "/dashboard/etracker",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: true,
  },
  {
    title: "Accounts",
    icon: Users,
    type: "link",
    badgeType: "primary",
    active: false,
    path: "/etracker/accounts"
  },
  {
    title: "Money In",
    icon: LogIn,
    type: "link",
    badgeType: "primary",
    active: false,
    path: "/finance/income"
  },
  {
    title: "Money Out",
    icon: LogOut,
    type: "link",
    badgeType: "primary",
    active: false,
    path: "/finance/expenses"
  },
  {
    title: "Statements",
    icon: List,
    type: "link",
    badgeType: "primary",
    path: "/finance/statements"
  },
  {
    title: "Budget",
    icon: Target,
    type: "link",
    badgeType: "primary",
    path: "/finance/budgets"
  },

];


export const C360MENUITEMS = [
  {
    path: "/dashboard/home",
    title: "Dashboard",
    icon: Home,
    type: "link",
    badgeType: "primary",
    active: true,
  },
  {
    title: "Contacts",
    icon: Users,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/contacts/default", title: "All Contacts", type: "link" },
      { path: "/contacts/groups", title: "All Groups", type: "link" },
      { path: "/contacts/grouptypes", title: "Groups types", type: "link" },
    ],
  },
  {
    title: "Finance",
    icon: DollarSign,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/finance/income", title: "Income", type: "link" },
      { path: "/finance/expenses", title: "Expense", type: "link" },
      { path: "/finance/accounts", title: "Accounts", type: "link" },
      { path: "/finance/mpesa", title: "Mpesa", type: "link" },
    ],
  },
  {
    title: "Meetings",
    icon: Users,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/meetings/default", title: "All Meetings", type: "link" },
      { path: "/meetings/meetingtypes", title: "Meeting types", type: "link" },
      // { path: "/contacts/grouptypes", title: "Groups types", type: "link" },
    ],
  },
  {
    title: "Messaging",
    icon: MessageSquare,
    type: "sub",
    badgeType: "primary",
    active: false,
    children: [
      { path: "/messaging/messages", title: "Send Messages", type: "link" },
      { path: "/messaging/uploaded_files", title: "Uploaded Files", type: "link" },
      // { path: "/messaging/scheduled_messages", title: "Scheduled Messages", type: "link" },
      { path: "/messaging/failed_messages", title: "Failed Messages", type: "link" },
      { path: "/messaging/config", title: "Config", type: "link" },
    ],
  },

  // {

  //     title: 'Messaging', icon: Home, type: 'sub', badgeType: 'primary', active: false, children: [

  //         { path: '/dashboard/default', title: 'Send Messages', type: 'link' },
  //         { path: '/dashboard/ecommerce', title: 'Scheduled Messages', type: 'link' },
  //         { path: '/dashboard/university', title: 'Failed Messages', type: 'link' }
  //     ]
  // },
  // {

  //     title: 'Finance', icon: Home, type: 'sub', badgeType: 'primary', active: false, children: [

  //         { path: '/dashboard/default', title: 'Accounts', type: 'link' },
  //         { path: '/dashboard/ecommerce', title: 'Income', type: 'link' },
  //         { path: '/dashboard/university', title: 'Expenses', type: 'link' },
  //         { path: '/dashboard/university', title: 'Mpesa', type: 'link' }
  //     ]
  // },
  // { path: '/dashboard/home',title: 'Settings', icon: Home, type: 'link', badgeType: 'primary',active: false},
  // { path: '/dashboard/home',title: 'Profile', icon: Home, type: 'link', badgeType: 'primary',active: false},

  // {
  //     title: 'Authentication', icon: Lock, type: 'sub', active: false, children: [
  //         { path: '/pages/login', type: 'link', title: 'Login Simple' },
  //         { path: '/pages/loginWithBgImg', type: 'link', title: 'Login With Bg Image' },
  //         { path: '/pages/loginWithVideo', type: 'link', title: 'Login With Bg Video' },
  //         { path: '/pages/signup', type: 'link', title: 'Register Simple ' },
  //         { path: '/pages/signupWithImg', type: 'link', title: 'Register With Bg Image ' },
  //         { path: '/pages/signupWithVideo', type: 'link', title: 'Register With Bg Video ' },
  //         { path: '/pages/unlockUser', type: 'link', title: 'Unlock User' },
  //         { path: '/pages/forgetPwd', type: 'link', title: 'Forget Password ' },
  //         { path: '/pages/resetPwd', type: 'link', title: 'Reset Password ' }
  //     ]
  // },
  //     {
  //         title: 'Maintenance', icon: Settings, path: '/pages/maintenance', type: 'link', active: false
  //     }
];
