import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "../../common/pageheader";
import axios from "../../../services/axios";
import Loader from "../../common/loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SweetAlert from "sweetalert2";

import { validationSchema } from "./validation_schema";

const genderItems = [
  { id: "Male", title: "Male" },
  { id: "Female", title: "Female" },
  { id: "Other", title: "Other" },
];

const MessagingForm = (props) => {
  let history = useHistory();
  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const [selectedRecipientType, setSelectedRecipientType] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [groups_list, setGroupList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { errors } = formState;
  const currUser = JSON.parse(localStorage.getItem("c360-user"));
  const goBack = () => {
    history.push("/messaging/messages");
  };

  const handleSelectRecipientType = (e) => {
    console.log(e);
    console.log(e.target.value);
    setSelectedRecipientType(e.target.value);
    // console.log(selectedRecipientType);
  };
  const onSubmit = (data) => {
    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }

    let formData = null;
    let endpoint = "/notifymany";

    if (selectedRecipientType == "Upload") {
      formData = new FormData();
      formData.append("message", data.textmessage);
      formData.append("message_file", selectedFile);
      formData.append("type", "SMS");
      formData.append("category", "WEBSMS");
      endpoint = "/notifyuploaded";
    } else {
      formData = { ...data, type: "SMS", category: "WEBSMS" };
    }

    axios
      .post(
        endpoint + "?tenantid=" + currUser.user.Partner.identifier,
        formData
      )
      .then((res) => {
        console.log(res);
        SweetAlert.fire({
          title: "Awesome!",
          text: "New contact saved successfully!",
          icon: "success",
        });

        // window.location.href = `${process.env.PUBLIC_URL}/messaging/messages`;
        history.push("/messaging/messages");
      })
      .catch((error) => {
        if (error.response) {
          SweetAlert.fire({
            title: "Error!",
            text: error.response.data.status_msg,
            icon: "error",
          });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          SweetAlert.fire({
            title: "Error!",
            text: "The request was made but no response was received",
            icon: "error",
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          SweetAlert.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
          });
        }
      });

    // console.log('testing');
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
    return false;
  };

  useEffect(() => {
    getGroups();
  }, []);

  //Fetch data from API endpoint
  const getGroups = async () => {
    setLoading(true);

    let currUser = JSON.parse(localStorage.getItem("c360-user"));
    let today = new Date();
    let searchdate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    const params = {
      tenantid: currUser.user.Partner.identifier,
      searchKey: "",
      action: "display",
      page: 1,
      pagesize: 25,
      startdate: "2020-01-01",
      enddate: searchdate,
    };

    let response = await axios.get("/groups", { params });
    console.log(response);
    setGroupList(response.data.groups);
    setLoading(false);
  };

  const handleFile = (e) => {
    let selected_file = e.target.files[0];
    setSelectedFile(selected_file);
  };

  return (
    <Fragment>
      <PageHeader title="New Message" parent="Messages" />

      <div className="col-lg-3"></div>
      <div className="col-lg-6">
        <div className="card">
          <div className="card-body">
            <div className="card m-3">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Recipient Type:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <select
                          onClick={handleSelectRecipientType}
                          name="recipient_type"
                          {...register("recipient_type")}
                          className={`form-control ${
                            errors.recipient_type ? "is-invalid" : ""
                          }`}
                        >
                          <option value=""></option>
                          <option value="Typed">Type recipients</option>
                          <option value="Group">Send to group</option>
                          <option value="Upload">Upload recipients</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display:
                        selectedRecipientType == "Group" ? "block" : "none",
                    }}
                  >
                    <div className="form-row">
                      <div className="col-3">
                        <label htmlFor="exampleFormControlInput1">
                          Select Group:<span className="font-danger">*</span>
                        </label>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <select
                            name="group"
                            {...register("group")}
                            className={`form-control ${
                              errors.group ? "is-invalid" : ""
                            }`}
                          >
                            <option value="">-Select one-</option>
                            <option value="0">All Contacts</option>
                            {groups_list.map((data) => (
                              <option value={data.id}>{data.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display:
                        selectedRecipientType == "Upload" ? "block" : "none",
                    }}
                  >
                    <div className="form-row">
                      <div className="col-3 ">
                        <label htmlFor="exampleFormControlInput1">
                          Select File:<span className="font-danger">*</span>
                        </label>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <input
                            onChange={handleFile}
                            type="file"
                            name="recipientsfile"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      display:
                        selectedRecipientType == "Typed" ? "block" : "none",
                    }}
                  >
                    <div className="form-row">
                      <div className="col-3">
                        <label htmlFor="exampleFormControlInput1">
                          Recipients:<span className="font-danger">*</span>
                        </label>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <textarea
                            name="recipients"
                            type="text"
                            {...register("recipients")}
                            className={`form-control ${
                              errors.recipients ? "is-invalid" : ""
                            }`}
                          />
                          <div className="invalid-feedback">
                            {errors.recipients?.message}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Message:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <textarea
                          name="textmessage"
                          type="text"
                          {...register("textmessage")}
                          className={`form-control ${
                            errors.textmessage ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.textmessage?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <input type="hidden" name="type" value="SMS" />
                  <input type="hidden" name="category" value="WEBSMS" />
                  <div className="form-row">
                    <div className="col-9">
                      <div className="form-group  pull-right">
                        <button type="submit" className="btn btn-primary mr-1">
                          Submit
                        </button>
                        {/* <button
                          type="button"
                          onClick={() => reset()}
                          className="btn btn-secondary"
                        >
                          Reset
                        </button> */}

                        <button
                          type="button"
                          onClick={goBack}
                          className="btn btn-danger"
                        >
                          {" "}
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3"></div>
    </Fragment>
  );
};
export default MessagingForm;
