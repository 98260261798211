import axios from "axios";

let currUser = JSON.parse(localStorage.getItem("c360-user"));

if (currUser) {
  axios.defaults.headers.common["Authorization"] = currUser.access_token;
}
console.log(process.env.REACT_APP_BASE_URL);
const instance = axios.create({
  baseURL:"/api/v2",
});

export default instance;
