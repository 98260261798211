import React, { Fragment, useState, useEffect } from "react";
import PageHeader from "../../common/pageheader";
import PaginationComponent from "../../common/pagination";
import axios from "../../../services/axios";
import { Edit } from "react-feather";
import SimpleLoader from "react-simple-dots-loader";

const Budget = () => {
  const [accounts_list, setAccountList] = useState([]);
  const [parent_list, setParentList] = useState([]);
  const [perPage, setPerPage] = useState(15);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  let currUser = JSON.parse(localStorage.getItem("c360-user"));

  useEffect(() => {
    getData();
  }, []);

  //Fetch data from API endpoint
  const getData = async () => {
    setLoading(true);

    let today = new Date();
    let searchdate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    const params = {
      tenantid: currUser.user.Partner.identifier,
      searchKey: searchKey,
      action: "display",
      page: currentPage,
      pagesize: perPage,
      startdate: "2020-01-01",
      enddate: searchdate,
    };

    let response = await axios.get("/account", { params });
    console.log(response);
    setAccountList(response.data.accounts);
    var parentNames = [];
    parentNames[0] = "Root Account";

    response.data.accounts.forEach((item) => {
      parentNames[item.id] = item.name;
    });
    setParentList(parentNames);
    setTotalCount(response.data.total);
    setLoading(false);
  };

  // Function to load a specific page of data from API
  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    // console.log(e);
    setAccountList([]);
    setCurrentPage(e);
    getData();
  };

  // Function to perform search based on provided searchKey
  const handleSearch = () => {
    console.log(searchKey);
    console.log("searchKey");
    setAccountList([]);
    getData();
  };

  // Function to perform redirect to edit page for the record. Notice the record is stord in localStorage
  const onClickHandler = (e) => {
    const member_id = e.target.getAttribute("data-item");
    const member = accounts_list.find((element) => element.id == member_id);
    localStorage.setItem("recordForEdit", JSON.stringify(member));
    window.location.href = `${process.env.PUBLIC_URL}/finance/accounts/edit`;
  };

  return (
    <Fragment>
      <PageHeader
        title="Budget"
        parent="Finance"
        onSearch={handleSearch}
        serchText={setSearchKey}
      />

      {accounts_list ? (
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-xl-12 xl-100">
                  <div className="user-status table-responsive">
                    Coming Soon
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default Budget;
