import React, { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PageHeader from "../../common/pageheader";
import axios from "../../../services/axios";
import Loader from "../../common/loader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SweetAlert from "sweetalert2";

import { validationSchema } from "./validation_schema";

const modes = [
  { id: "1", title: "CASH" },
  { id: "2", title: "M-PESA" },
  { id: "3", title: "ACCOUNT" },
  { id: "4", title: "CHEQUE" },
];

const IncomeEdit = (props) => {
  let history = useHistory();
  const recordForEdit = JSON.parse(localStorage.getItem("recordForEdit"));
  // console.log(recordForEdit);
  const defaults = {
    firstname: recordForEdit.firstName,
    lastname: recordForEdit.lastName,
    phone: recordForEdit.phone,
    amount: recordForEdit.amount,
    account: recordForEdit.AccountId,
    paymentFor: recordForEdit.paymentFor,
    mode: recordForEdit.mode,
    sendMessage: false,
    reference: recordForEdit.reference,
    dateOfTransaction: recordForEdit.dateOfTransaction,
  };

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: defaults,
  };

  const [account_list, setAccountList] = useState([]);
  const [loading, setLoading] = useState(false);

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  const currUser = JSON.parse(localStorage.getItem("c360-user"));
  const goBack = () => {
    history.push("/finance/income");
  };

  useEffect(() => {
    getData();
  }, []);

  //Fetch data from API endpoint
  const getData = async () => {
    setLoading(true);
    let currUser = JSON.parse(localStorage.getItem("c360-user"));
    let today = new Date();
    let searchdate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    const params = {
      tenantid: currUser.user.Partner.identifier,
      searchKey: "",
      action: "display",
      page: 1,
      pagesize: 25,
      startdate: "2020-01-01",
      enddate: searchdate,
    };

    let response = await axios.get("/account", { params });
    console.log(response);
    setAccountList(response.data.accounts.filter(a=>a.type === 'CR'));
    setLoading(false);
  };

  const onSubmit = (data) => {
    console.log(data);
    if (!currUser || !currUser.user || !currUser.access_token) {
      window.location.href = `${process.env.PUBLIC_URL}/login`;
    }
    axios
      .put(
        "/income/" +
          recordForEdit.id +
          "?tenantid=" +
          currUser.user.Partner.identifier,
        data
      )
      .then((res) => {
        console.log(res);
        SweetAlert.fire({
          title: "Awesome!",
          text: "New income saved successfully!",
          icon: "success",
        });

        history.push('/finance/income');
      })
      .catch((error) => {
        if (error.response) {
          SweetAlert.fire({
            title: "Error!",
            text: error.response.data.status_msg,
            icon: "error",
          });
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request);
          SweetAlert.fire({
            title: "Error!",
            text: "The request was made but no response was received",
            icon: "error",
          });
        } else {
          // Something happened in setting up the request that triggered an Error
          SweetAlert.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
          });
        }
      });

    // console.log('testing');
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(data, null, 4));
    return false;
  };

  return (
    <Fragment>
      <PageHeader title="Edit Income" parent="Income" />

      <div className="col-lg-3"></div>
      <div className="col-lg-6">
        <div className="card">
          <div className="card-body">
            <div className="card m-3">
              <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        FirstName:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          name="firstname"
                          type="text"
                          {...register("firstname")}
                          className={`form-control ${
                            errors.firstname ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.firstname?.message}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Last Name:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          name="lastname"
                          type="text"
                          {...register("lastname")}
                          className={`form-control ${
                            errors.lastname ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.lastname?.message}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Amount:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          name="amount"
                          type="text"
                          {...register("amount")}
                          className={`form-control ${
                            errors.amount ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.amount?.message}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Mobile:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          name="phone"
                          type="text"
                          {...register("phone")}
                          className={`form-control ${
                            errors.phone ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.phone?.message}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Account:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <select
                          name="title"
                          {...register("account")}
                          className={`form-control ${
                            errors.account ? "is-invalid" : ""
                          }`}
                        >
                          {account_list.map((data) => (
                            <option value={data.id}>{data.name}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Channel:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <select
                          name="title"
                          {...register("mode")}
                          className={`form-control ${
                            errors.mode ? "is-invalid" : ""
                          }`}
                        >
                          {modes.map((data) => (
                            <option value={data.title}>{data.title}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Date of Transaction:
                        <span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          name="dob"
                          type="date"
                          {...register("dateOfTransaction")}
                          className={`form-control ${
                            errors.dateOfTransaction ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.dateOfTransaction?.message}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Payment For:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          name="paymentFor"
                          type="text"
                          {...register("paymentFor")}
                          className={`form-control ${
                            errors.paymentFor ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.paymentFor?.message}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Reference:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          name="reference"
                          type="text"
                          {...register("reference")}
                          className={`form-control ${
                            errors.reference ? "is-invalid" : ""
                          }`}
                        />
                        <div className="invalid-feedback">
                          {errors.reference?.message}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-3">
                      <label htmlFor="exampleFormControlInput1">
                        Send Message:<span className="font-danger">*</span>
                      </label>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <input
                          name="sendMessage"
                          type="checkbox"
                          {...register("message")}
                          id="sendMessage"
                          className={` ${
                            errors.sendMessage ? "is-invalid" : ""
                          }`}
                        />

                        <div className="invalid-feedback">
                          {errors.sendMessage?.message}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-9">
                      <div className="form-group  pull-right">
                        <button type="submit" className="btn btn-primary mr-1">
                          Submit
                        </button>

                        <button
                          type="button"
                          onClick={goBack}
                          className="btn btn-danger"
                        >
                          {" "}
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3"></div>
    </Fragment>
  );
};
export default IncomeEdit;
